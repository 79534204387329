<template>
  <div>
    <div class="sign">
      <!-- <div class="sign_header_tip" v-if="headerTipShow">
        请将签名和身份证号拖到指定位置后完成签约
      </div> -->
      <div class="sign_title">签约系统</div>
      <!-- <div id="sign_time">{{ signTime }}</div> -->
      <div class="sign_con" ref="veriface">
        <div id="container">
          <div id="pop" style="text-align: center">
            <div class="signbox" @touchstart="start($event)" @touchmove.prevent="move($event)" @touchend="end($event)">
              <img :src="resultImg" alt="" width="100%" />
            </div>
            <div class="idCode" @touchstart="idStart($event)" @touchmove.prevent="idMove($event)"
              @touchend="idEnd($event)">
              <input class="idInput" type="text" :disabled="!noIdCode" placeholder="点击输入身份证号" @focus="idFocus"
                @blur="idBlur" v-model="idNumber" maxlength="18" />
            </div>
          </div>
        </div>
      </div>
      <div class="sign_header_tip" v-if="headerTipShow">
        <p v-if="isNeedIdc == 1">请将签名及身份证号移动到指定位置</p>
        <p v-else>请将签名拉到指定位置</p>
      </div>
      <div class="sign_header_tip" v-if="!headerTipShow"></div>
      <div class="sign_button" :class="banSign ? 'sign_button_add' : ''" @click="popupOpen" v-if="type == 1">
        1.已阅读协议去提交签名
      </div>
      <div class="sign_button_add" v-if="type == 2" @click="fillIdCode">
        2.填写身份证号码
      </div>
      <div class="sign_button_add" v-if="type == 3" @click="completeSigning"
        :class="[readOnly == true ? 'read_only' : '']">
        {{ nn }} {{ finsh }}
      </div>
      <!-- 提醒 -->
      <div class="sign_tip" v-if="tipShow">
        <p>协议读取到底部后进行签名</p>
      </div>
      <div class="sign_tip" v-if="!tipShow">
        <p v-if="isNeedIdc == 1">请将签名和身份证号码拉到指定位置</p>
        <p v-else>请将签名拉到指定位置</p>
      </div>
      <div class="sign_popup" v-if="popupShow">
        <div class="sign_popup_title">
          <div class="sign_popup_txt">{{ isAgree ? '请本人在下方空白处签字' : '' }}</div>
          <div class="sign_popup_cancel" @click="popupClose">
            <img width="100%" height="100%" src="../assets/img/signCancel.png" alt="" />
          </div>
        </div>
        <div class="sign_popup_con">
          <vue-esign ref="esign" :width="325" :height="200" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
            :bgColor.sync="bgColor" class="sign_popup_con_board" />
        </div>
        <div class="sign_popup_item">
          <div class="sign_popup_item1" @click="handleReset">清除重写</div>
          <div v-if="isAgree" class="sign_popup_item2" @click="signSubmit">提交签名</div>
          <div v-else class="sign_popup_item2" @click="agreeSubmit">提交同意</div>
        </div>
      </div>
    </div>
    <div v-if="popupShow" class="sign_shadow"></div>
    <div v-if="isAgreeShow" class="agree">请在下框填写“已同意”，即表示“本人已同意合约中以上条款”</div>
    <div class="loading" v-if="loadingShow">
      <div class="loadingBox">
        <img width="100%" height="100%" src="../assets/img/loading.gif" alt="" />
        <p>加载中</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import html2canvas from "html2canvas";
import PDFJS from "pdfjs-dist";
import { jsPDF } from "jspdf";

export default {
  name: "sign",
  data() {
    return {
      popupShow: false, // 签字弹窗展示
      // isSign: false, // 是否签字
      banSign: false, // 是否可以签字
      lineWidth: 5, // 画笔粗细
      lineColor: "#000000", // 画笔颜色
      bgColor: "", // 画布背景
      isCrop: false, // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
      onlyId: "", // 唯一码
      contractUrl: "", // 未签名协议地址
      signContractUrl: "", // 已签名协议地址
      resultImg: "", // 签名图片
      contractImg: [], // 协议图片
      x: "",
      y: "",
      loadingShow: false, // laod加载
      readOnly: false, // 完成签署框只读
      tipShow: true, //提示显示隐藏
      finsh: "完成签署",
      signRangeX: "",
      signRangeY: "",
      signRangeNumber: "",
      isDrag: true,
      type: 1,
      noIdCode: true,
      idNumber: "",
      isNeedIdc: "",
      nn: "3.",
      headerTipShow: false,
      signStatus: 1,
      nowYear: "",
      nowMonth: "",
      nowdates: "",
      signTime: "",
      rawPageNum: 0,
      isAgree: false, // 签 “已同意”
      agreeImg: "", // 签 “已同意” 图片
      isAgreeShow: false,
    };
  },
  methods: {
    // 点击 已阅读协议去提交签名 触发事件
    popupOpen() {
      if (this.isAgree) {
        if (this.banSign) {
          this.getSignTime();
          this.popupShow = true;
          let aa = document.getElementsByClassName("canvas");
          console.log(aa);
          for (let i = 0; i < aa.length; i++) {
            this.contractImg.push(aa[i].toDataURL("image/png"));
          }
        } else {
          this.scrollBottom();
          Toast("请阅读完协议再进行签约");
        }
      } else {
        this.isAgreeShow = true;
        Toast("请先同意签署协议");
        this.popupShow = true;
      }
    },
    // 检测滚动触底事件
    scrollBottom() {
      this.$refs.veriface.addEventListener("scroll", (e) => {
        let scrollTop = e.target.scrollTop;
        let windowHeight = e.target.clientHeight;
        let scrollHeight = e.target.scrollHeight;
        // console.log(
        //   scrollTop,
        //   windowHeight,
        //   scrollTop + windowHeight,
        //   scrollHeight
        // );
        if (scrollTop + windowHeight >= scrollHeight - 50) {
          this.banSign = true;
        }
      });
    },
    // 点击 X 触发事件
    popupClose() {
      this.popupShow = false;
      this.isAgreeShow = false;
    },
    // 点击 清除重写 触发事件
    handleReset() {
      this.$refs["esign"].reset(); //清空画布
    },
    // 点击 提交签名 触发事件
    async signSubmit() {
      this.headerTipShow = true;
      await this.$refs["esign"]
        .generate()
        .then((res) => {
          this.resultImg = res; // 得到了签字生成的base64图片
          // this.isSign = true;
          if (parseInt(this.isNeedIdc)) {
            this.type = 2;
          } else {
            this.type = 3;
            this.nn = "2.";
          }
          this.tipShow = false;
          this.popupClose();
          Toast("请将底部签名拖到指定位置");
        })
        .catch((err) => {
          //  没有签名，点击提交签名时调用
          Toast("未检测到您的签名");
        });
      this.signImgCanvas();
      let signbox = document.getElementsByClassName("signbox");
      signbox[0].style.display = "block";
    },
    // 点击 确认同意 触发事件
    async agreeSubmit() {
      await this.$refs["esign"]
        .generate()
        .then((res) => {
          this.isAgreeShow = false;
          this.agreeImg = res; // 得到了签字生成的base64图片
          this.isAgree = true;
          this.popupClose();
          console.log(res);
          let canvas_box = document.getElementsByClassName("canvas_box");
          let agreeDiv = document.createElement("div");
          agreeDiv.className = "agree_div";
          agreeDiv.style.width = "50%";
          agreeDiv.style.position = "absolute";
          agreeDiv.style.fontSize = "0.1rem";
          agreeDiv.style.bottom = "0rem";
          agreeDiv.style.left = "0rem";
          agreeDiv.style.color = "#666";
          agreeDiv.innerHTML = '<div><p>请在下框填写“已同意”，即表示“本人已同意合约中以上条款”</p><img class="agree_img" src="' + this.agreeImg + '" alt=""></div>';
          canvas_box[canvas_box.length - 1].appendChild(agreeDiv);

        })
        .catch((err) => {
          //  没有签名，点击提交签名时调用
          Toast("未检测到您的签名");
        });
    },
    // 点击 身份证号 触发事件
    fillIdCode() {
      let idCode = document.getElementsByClassName("idCode");
      idCode[0].style.display = "block";
      document.getElementsByClassName("idInput")[0].focus();
      this.type = 3;
    },
    // 身份证号聚焦
    idFocus() {
      let idInput = document.getElementsByClassName("idInput");
      idInput[0].style.border = "1px solid black";
    },
    // 身份证失焦
    idBlur() {
      let idInput = document.getElementsByClassName("idInput");
      idInput[0].style.border = "none";
    },
    // 点击 完成签署 触发事件
    completeSigning() {
      if (parseInt(this.isNeedIdc)) {
        let idRules =
          /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/;
        // if (this.idNumber.length == 18) {
        if (idRules.test(this.idNumber)) {
          this.toPdf();
          this.isDrag = false;
          this.noIdCode = false;
        } else {
          Toast("输入有误");
        }
      } else {
        this.getSignTime();
        this.toPdf();
        this.isDrag = false;
        this.noIdCode = false;
      }
    },
    // 获取唯一码 onlyId
    getOnlyId() {
      this.onlyId = localStorage.getItem("onlyId");
    },
    // 获取协议地址 contractUrl
    getContractUrl() {
      this.contractUrl = localStorage.getItem("contractUrl");
      // this.pdfToPic();
      // console.log(this.contractUrl, "页面已加载获取pdf地址");
    },
    // 获取身份证
    getIsNeedIdc() {
      this.isNeedIdc = localStorage.getItem("isNeedIdc");
    },
    // PDF转图片
    pdfToPic() {
      this.loadingShow = true;
      var container = document.getElementById("container");
      let scale = 2;
      let pop = "#pop";
      let canvas_box = "canvas_box";
      let pages = "page";
      container.style.display = "block";
      PDFJS.getDocument({
        // url: "https://img.crm.yoyolooming.com/crm/contract/raw/1664526548930.pdf",
        url: this.contractUrl,
        withCredentials: false,
      })
        .promise.then((pdf) => {
          var shownPageCount = pdf.numPages < 50 ? pdf.numPages : 50;
          var getPageAndRender = (pageNumber) => {
            this.rawPageNum = pageNumber;
            pdf.getPage(pageNumber).then((page) => {
              var viewport = page.getViewport(scale);
              var clientWidth = document.documentElement.clientWidth;
              // pop
              let $pop = document.getElementById("pop");
              $pop.style.width = clientWidth.width + "px";
              // ------------------------
              // 画布盒子
              let $canvas_box = document.createElement("div");
              $canvas_box.className = canvas_box;
              $canvas_box.style.position = "relative";
              $canvas_box.id = pages + (page.pageIndex + 1) + "box";
              $canvas_box.page = pageNumber;
              this.signRangeNumber = pageNumber;

              // pdf画布
              let $canvas = document.createElement("canvas");
              $canvas.className = "canvas";
              $canvas.height = Math.floor(viewport.height);
              $canvas.width = Math.floor(viewport.width);
              $canvas.style.width = clientWidth + "px";
              $canvas.style.height =
                parseInt((clientWidth / $canvas.width) * $canvas.height) + "px";
              // $canvas.style.transform = "scale(1.1)";
              this.signRangeX = clientWidth;
              this.signRangeY = parseInt(
                (clientWidth / $canvas.width) * $canvas.height
              );
              $canvas.page = pageNumber;
              // 创建时间戳
              if (this.signStatus == 0) {
                let timedDiv = document.createElement("div");
                timedDiv.className = "sign_time";
                timedDiv.style.position = "absolute";
                timedDiv.style.fontSize = "0.3rem";
                timedDiv.style.bottom = "1rem";
                timedDiv.style.right = "1rem";
                timedDiv.style.color = "#666";
                timedDiv.innerHTML = this.signTime;
                $canvas_box.appendChild($canvas);
                $canvas_box.appendChild(timedDiv);
                document.querySelector(pop).appendChild($canvas_box);
              } else {
                $canvas_box.appendChild($canvas);
                document.querySelector(pop).appendChild($canvas_box);
              }
              // ----------------------
              page.render({
                canvasContext: $canvas.getContext("2d"),
                viewport: viewport,
              });
              this.loadingShow = false;
            });
            if (pageNumber < shownPageCount) {
              pageNumber++;
              getPageAndRender(pageNumber);
            }
          };
          getPageAndRender(1);
          // this.$nextTick(() => {
          // let aa = document.getElementsByClassName("canvas");
          // console.log(aa[0], "12222222232435435aaaaaaaa");
          // });
        })
        .catch(function (error) {
          Toast("协议无法加载");
        });
    },
    // 图片放到canvas上
    signImgCanvas() { },
    // 签名的移动
    start(e) {
      let div = document.getElementsByClassName("signbox");

      this.x =
        parseInt(e.targetTouches[0].clientX) - parseInt(div[0].offsetLeft);

      this.y = e.targetTouches[0].clientY - div[0].offsetTop;
    },
    move(e) {
      if (this.isDrag) {
        let div = document.getElementsByClassName("signbox");
        // 左右移动
        if (e.targetTouches[0].clientX - this.x < 0) {
          div[0].style.left = 0 + "px";
        } else if (e.targetTouches[0].clientX - this.x > this.signRangeX - 55) {
          div[0].style.left = this.signRangeX - 55 + "px";
        } else {
          div[0].style.left = e.targetTouches[0].clientX - this.x + "px";
        }
        // 上下移动
        if (
          e.targetTouches[0].clientY - this.y <
          this.signRangeY * (this.signRangeNumber - 1) + 23
        ) {
          div[0].style.top =
            this.signRangeY * (this.signRangeNumber - 1) + 23 + "px";
        } else if (
          e.targetTouches[0].clientY - this.y >
          this.signRangeY * this.signRangeNumber - 23
        ) {
          div[0].style.top = this.signRangeY * this.signRangeNumber - 23 + "px";
        } else {
          div[0].style.top = e.targetTouches[0].clientY - this.y + "px";
        }
      }
    },
    end(e) {
      // console.log(e);
    },
    // 身份证移动
    idStart(e) {
      let div = document.getElementsByClassName("idCode");

      this.x =
        parseInt(e.targetTouches[0].clientX) - parseInt(div[0].offsetLeft);

      this.y = e.targetTouches[0].clientY - div[0].offsetTop;
    },
    idMove(e) {
      if (this.isDrag) {
        let div = document.getElementsByClassName("idCode");
        // 左右移动
        if (e.targetTouches[0].clientX - this.x < 0) {
          div[0].style.left = 0 + "px";
        } else if (
          e.targetTouches[0].clientX - this.x >
          this.signRangeX - 127
        ) {
          div[0].style.left = this.signRangeX - 127 + "px";
        } else {
          div[0].style.left = e.targetTouches[0].clientX - this.x + "px";
        }
        // 上下移动
        if (
          e.targetTouches[0].clientY - this.y <
          this.signRangeY * (this.signRangeNumber - 1) + 23
        ) {
          div[0].style.top =
            this.signRangeY * (this.signRangeNumber - 1) + 23 + "px";
        } else if (
          e.targetTouches[0].clientY - this.y >
          this.signRangeY * this.signRangeNumber - 23
        ) {
          div[0].style.top = this.signRangeY * this.signRangeNumber - 23 + "px";
        } else {
          div[0].style.top = e.targetTouches[0].clientY - this.y + "px";
        }
      }
    },
    idEnd() { },
    // 保存pdf
    toPdf() {
      const excludeClass = ["el-dialog__headerbtn", "footer"];
      // console.log(window.devicePixelRatio, "wuwuuwuwu");
      this.loadingShow = true;
      this.$nextTick(() => {
        window.scrollTo(5, 0);
        html2canvas(document.getElementById("pop"), {
          backgroundColor: "#FFFFFF",
          useCORS: true,
          scale: window.devicePixelRatio,
          // x: 0, //whq
          // y: window.pageYOffset, //whq
          // windowHeight: document.getElementById("pop").scrollHeight, //whq
          ignoreElements(element) {
            return (
              element.classList &&
              Array.from(element.classList).some((key) =>
                excludeClass.includes(key)
              )
            );
          },
        }).then((res) => {
          this.createPdf(res);
          this.loadingShow = false;
        });
      });
    },
    async createPdf(canvas) {
      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      //一页pdf显示html页面生成的canvas高度;
      const pageHeight = (contentWidth / 592.28) * 841.89;
      //未生成pdf的html页面高度
      let leftHeight = contentHeight;
      //页面偏移
      let position = 20;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 595.28;
      const imgHeight = (592.28 / contentWidth) * contentHeight;
      const pageData = canvas.toDataURL("image/jpeg", 1.0);
      // pdf实例
      const pdf = new jsPDF("", "pt", "a4");


      // pdf.addImage(pageData, "JPEG", 20, 20, imgWidth - 40, imgHeight - 40);
      // pdf.addPage();

      //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, "JPEG", 20, 20, imgWidth - 40, imgHeight - 40);
        pdf.addPage();
      } else {
        while (leftHeight > 0) {
          pdf.addImage(
            pageData,
            "JPEG",
            20,
            position,
            imgWidth - 40,
            imgHeight - 40
          );
          leftHeight -= pageHeight;
          position -= 841.89;
          //避免添加空白页
          // if (leftHeight > 0) {
          pdf.addPage();
          // }
        }
      }
      //输出保存命名为content的pdf
      let a = pdf.output("datauristring");
      let s = a.indexOf(",") + 1;
      let r = a.slice(s);
      this.signContractUrl = r;
      // 调取 提交协议 接口
      let params = {
        base64pdf: this.signContractUrl,
        onlyId: this.onlyId,
        rawPageNum: this.rawPageNum,
      };
      this.loadingShow = true;
      await this.$axios
        .post("/api/template/contract/submitContractV2", params, {
          timeout: 30000,
        })
        .then((res) => {
          if (res.data.data) {
            this.loadingShow = false;
            this.readOnly = true;
            this.finsh = "完成签约";
            this.nn = "";
            Toast(res.data.msg);
            this.$router.push({
              path: "/",
              query: { onlyId: this.onlyId },
            });
          } else {
            this.loadingShow = false;
            Toast(res.data.msg);
            // Toast("签约失败,请重新签约");
          }
        })
        .catch((error) => {
          this.loadingShow = false;
          this.readOnly = true;
          this.finsh = "完成签约";
          this.nn = "";
          this.$router.push({
            path: "/",
            query: { onlyId: this.onlyId },
          });
        });
    },
    async getStatus() {
      await this.$axios
        .get("/api/template/contract/contractInfo?onlyId=" + this.onlyId)
        .then((res) => {
          if (res.data.data) {
            this.signStatus = res.data.data.status;
          }
        });
      if (this.signStatus) {
        this.type = 3;
        this.readOnly = true;
        this.nn = "";
        this.finsh = "您已经完成签约";
      }
    },
    getSignTime() {
      var current = new Date();
      this.nowYear = current.getFullYear();
      this.nowMonth = current.getMonth() + 1;
      this.nowdates = current.getDate();
      this.signTime =
        "签约时间:" +
        this.nowYear +
        "年" +
        this.nowMonth +
        "月" +
        this.nowdates +
        "日";
    },
  },
  mounted() {
    // 获取签约时间
    this.getSignTime();
    // 滚动条到底事件
    this.scrollBottom();
    // 获取唯一码 onlyId
    this.getOnlyId();
    // 获取协议地址 contractUrl
    this.getContractUrl();
    // 获取是否身份证
    this.getIsNeedIdc();
    // PDF转图片
    this.pdfToPic();
    // 获取签约状态
    this.getStatus();
  },
};
</script>

<style lang="less">
// img {
//   -webkit-touch-callout: none;
// }

.sign {
  .sign_header_tip {
    width: 100%;
    height: 3rem;
    // border: 0.0267rem solid red;
    // position: absolute;
    // top: 0.2667rem;
    // left: 50%;
    // transform: translateX(-50%);
    text-align: center;
    color: rgb(189, 39, 39);
    font-size: 0.9067rem;
  }

  .sign_title {
    width: 5.3333rem;
    height: 1.3333rem;
    margin: auto;
    margin-top: 2.7733rem;
    text-align: center;
    color: #333333;
    font-size: 0.9067rem;
    font-weight: bold;
  }

  .sign_con {
    width: 100%;
    margin-top: 0.64rem;
    // margin-bottom: 2.3467rem;
    height: 65vh;
    background-color: #fffcec;
    overflow-y: scroll;

    #pop {
      // width: 1200px !important;
      position: relative;

      // overflow: hidden;
      .signbox {
        width: 50px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        display: none;
        position: absolute;
        bottom: 200px;
        left: 45%;
        z-index: 999;
        background-color: rgba(204, 202, 202, 0.2);
      }

      .idCode {
        height: 30px;
        position: absolute;
        bottom: 175px;
        left: 45%;
        display: none;
        z-index: 21;

        input {
          width: 122px;
          height: 30px;
          font-size: 10px;
          background-color: transparent;
        }
      }
    }
  }

  .sign_tip {
    width: 80%;
    color: #666;
    margin: auto;
    text-align: center;
    margin-top: 0.5333rem;
    font-size: 0.4rem;
  }

  .sign_button {
    width: 85%;
    height: 2.6667rem;
    line-height: 2.6667rem;
    background-color: #ccc;
    margin: auto;
    // margin-top: 2.3467rem;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.8533rem;
    border-radius: 0.64rem;
  }

  .sign_button_add {
    width: 85%;
    height: 2.6667rem;
    line-height: 2.6667rem;
    background-color: #2285fc;
    margin: auto;
    // margin-top: 2.3467rem;
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.8533rem;
    border-radius: 0.64rem;
  }

  .sign_popup {
    width: 100%;
    height: 22.3467rem;
    background-color: #fff;
    position: fixed;
    z-index: 999;
    bottom: 0%;
    border-radius: 0.64rem 0.64rem 0 0;

    .sign_popup_title {
      height: 3.04rem;
      position: relative;

      .sign_popup_txt {
        width: 10.6667rem;
        line-height: 3.04rem;
        margin: auto;
        color: #333333;
        font-size: 0.9067rem;
        font-weight: bold;
      }

      .sign_popup_cancel {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.4rem;
        right: 0.8rem;
      }
    }

    .sign_popup_con {
      width: 92%;
      margin: auto;
      height: 12.9333rem;
      background-color: #fafafa;
      overflow: hidden;

      .sign_popup_con_board {
        margin: auto;
        border: 0.0267rem solid #ccc;
      }
    }

    .sign_popup_item {
      width: 92%;
      margin: auto;
      margin-top: 1.36rem;
      height: 2.6667rem;
      line-height: 2.6667rem;
      display: flex;
      justify-content: space-between;

      .sign_popup_item1 {
        width: 46%;
        text-align: center;
        border: 0.0267rem solid #2285fc;
        color: #2285fc;
        border-radius: 0.64rem;
      }

      .sign_popup_item2 {
        width: 46%;
        text-align: center;
        border-radius: 0.64rem;
        background-color: #2285fc;
        color: #ffffff;
      }
    }
  }

  .read_only {
    pointer-events: none;
    background-color: #ccc;
    color: #ffffff;
  }
}

.sign_shadow {
  width: 100%;
  height: 100vh;
  background-color: #000000;
  opacity: 70%;
  position: fixed;
  top: 0;
}

.loading {
  width: 100%;
  height: 100vh;
  background: #666666;
  opacity: 0.5;
  position: fixed;
  top: 0%;
  z-index: 999;

  .loadingBox {
    width: 3rem;
    height: 3rem;
    margin: 50% auto;
    text-align: center;

    p {
      font-size: 0.32rem;
    }
  }
}

.agree {
  width: 80%;
  text-align: center;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, 0%);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
}

.agree_img {
  width: 3rem;
  background: rgb(245, 243, 244);
}
</style>