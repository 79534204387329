<template>
  <div>
    <div class="tosign">
      <div class="tosign_title">
        <p>云梦灯塔考研</p>
        <p>课程协议签约系统</p>
      </div>
      <div class="tosign_con">
        <div class="tosign_con_title">你收到1个协议，共1个文件待签署</div>
        <div class="tosign_con_info">
          <div class="tosign_con_info_title">
            <div class="left">任务主题:</div>
            <div class="right">{{ userList.title }}</div>
          </div>
          <div class="tosign_con_info_initiator">
            <div class="left">发起方：</div>
            <div class="right">{{ userList.originator }}</div>
          </div>
          <div class="tosign_con_info_signtory">
            <div class="left">签署人：</div>
            <div class="right">{{ userList.name }}</div>
          </div>
          <div class="tosign_con_info_status">
            <div class="left">签约状态：</div>  
            <div class="right" v-if="userList.status == 0 && exist">待签约</div>
            <div class="right" v-if="userList.status == 1 && exist">已签约</div>
            <div class="right" v-if="!exist">合同不存在</div>
          </div>
        </div>
      </div>
      <div class="tosign_button" @click="handleToSign" v-if="!userList.status">
        去签约
      </div>
      <div class="tosign_button" v-if="userList.status">签约完成</div>
      <div class="tosign_button look_button" v-if="userList.status" @click="viewDownload">
        <p>查看及下载已签约合同</p>
        <!-- <a :href="contractUrl" target="_blank" style="color: #fff"
          >查看及下载已签约合同</a
        > -->
      </div>  
    </div>
  </div>
</template>

<script>
export default {
  name: "Tosign",
  data() {
    return {
      onlyId: "", // 协议id
      userList: {},
      contractUrl: "", // 签约完成后的协议
      exist: true
    };
  },
  methods: {
    // 点击 去签约 触发事件
    async handleToSign() {
      // console.log("跳转到登录页");
      this.$router.push({
        path: "/login",
      });
      let params = {
        onlyId: localStorage.getItem("onlyId"),
        device: localStorage.getItem("device"),
        system: localStorage.getItem("system"),
      };
      await this.$axios
        .post("/api/template/contract/saveDeviceInfo", params)
        .then((res) => {
          console.log(res);
        });
    },
    // 获取 onlyId
    async getOnlyId() {
      let url = window.location.href;
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("=");
        this.onlyId = strs[1];
      }
      localStorage.setItem("onlyId", this.onlyId);
      await this.$axios
        .get("/api/template/contract/contractInfo?onlyId=" + this.onlyId)
        .then((res) => {
          if (res.data.data) {
            this.exist = true
            this.userList = res.data.data;
            localStorage.setItem("phoneNum", this.userList.phoneNum);
            localStorage.setItem("isNeedIdc", this.userList.isNeedIdc);
          } else {
            this.exist = false
          }
          this.getViewContract();
        });
    },
    // 获取手机型号
    getPhoneModels() {
      let webLog = {
        device: "null",
        system: "null",
      };
      let userAgent = navigator.userAgent;
      // 获取微信版本
      let m1 = userAgent.match(/MicroMessenger.*?(?= )/);
      if (m1 && m1.length > 0) {
        webLog.wechat = m1[0];
      }
      // 苹果手机
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        // 获取设备名
        if (userAgent.includes("iPad")) {
          webLog.device = "iPad";
        } else {
          webLog.device = "iPhone";
        }
        // 获取操作系统版本
        m1 = userAgent.match(/iPhone OS .*?(?= )/);
        if (m1 && m1.length > 0) {
          webLog.system = m1[0];
        }
      }
      // 安卓手机
      if (userAgent.includes("Android")) {
        // 获取设备名
        m1 = userAgent.match(/Android.*; ?(.*(?= Build))/);
        if (m1 && m1.length > 1) {
          webLog.device = m1[1];
        }
        // 获取操作系统版本
        m1 = userAgent.match(/Android.*?(?=;)/);
        if (m1 && m1.length > 0) {
          webLog.system = m1[0];
        }
      }
      localStorage.setItem("device", webLog.device);
      localStorage.setItem("system", webLog.system);
    },
    // 获取协议地址
    async getViewContract() {
      await this.$axios
        .get(
          "/api/template/contract/viewContract?onlyId=" +
          this.onlyId +
          "&phoneNum=" +
          this.userList.phoneNum
        )
        .then((res) => {
          console.log(res.data.data);
          this.contractUrl = res.data.data;
          localStorage.setItem("contractUrl", res.data.data);
        });
    },
    // 查看并下载已经签约的pdf
    viewDownload() {
      let a = document.createElement("a");
      a.setAttribute("href", this.contractUrl); //a.href='文件链接'
      a.setAttribute("download", "签约合同"); //a.download='文件名'
      a.click();
      this.$router.push({
        path: "/sign",
      });
    },
  },
  mounted() {
    // 获取 onlyId
    this.getOnlyId();
    // 获取手机型号
    this.getPhoneModels();
  },
};
</script>

<style lang="less">
.tosign {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/toSignBackground.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: scroll;

  .tosign_title {
    margin-top: 5.8933rem;
    margin-left: 1.6rem;
    font-size: 1.0133rem;
    color: #0051b2;
    font-weight: bold;

    p:nth-child(2) {
      margin-top: 0.64rem;
    }
  }

  .tosign_con {
    width: 92%;
    // height: 14.9333rem;
    background-color: #fff;
    border-radius: 0.64rem;
    margin: auto;
    margin-top: 2.3467rem;
    overflow: hidden;

    .tosign_con_title {
      width: 80%;
      margin: auto;
      margin-top: 1.6rem;
      margin-bottom: 1.6533rem;
      text-align: center;
      color: #333333;
      font-weight: bold;
    }

    .tosign_con_info {
      width: 90%;
      margin: auto;

      .tosign_con_info_title {
        font-weight: bold;
        display: flex;

        .left {
          width: 20%;
          color: #999999;
          font-size: 0.7467rem;
        }

        .right {
          color: #2f8cfc;
          font-size: 0.6933rem;
        }
      }

      .tosign_con_info_initiator {
        font-weight: bold;
        display: flex;
        margin-top: 1.2267rem;

        .left {
          width: 20%;
          color: #999999;
          font-size: 0.7467rem;
        }

        .right {
          color: #2f8cfc;
          font-size: 0.6933rem;
        }
      }

      .tosign_con_info_signtory {
        font-weight: bold;
        display: flex;
        margin-top: 1.28rem;

        .left {
          width: 20%;
          color: #999999;
          font-size: 0.7467rem;
        }

        .right {
          color: #2f8cfc;
          font-size: 0.6933rem;
        }
      }

      .tosign_con_info_status {
        font-weight: bold;
        display: flex;
        margin-top: 1.68rem;
        margin-bottom: 3.44rem;

        .left {
          width: 20%;
          color: #999999;
          font-size: 0.7467rem;
        }

        .right {
          color: #2f8cfc;
          font-size: 0.6933rem;
        }
      }
    }
  }

  .tosign_button {
    width: 85%;
    height: 2.6667rem;
    line-height: 2.6667rem;
    text-align: center;
    border-radius: 0.64rem;
    background-color: #2285fc;
    color: #fff;
    margin: auto;
    margin-top: 3.2rem;
    font-size: 0.8533rem;
    font-weight: bold;
  }

  .look_button {
    margin-top: 1.2rem;
  }
}
</style>