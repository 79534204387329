import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入全局样式
import "./styles/reset.css"
// 导入axios
import axios from 'axios'
// 导入vant
import Vant from 'vant';
import 'vant/lib/index.css';

import vueEsign from 'vue-esign'
Vue.use(vueEsign)
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
