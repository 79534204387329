<template>
  <div>
    <div class="login">
      <div class="login_title">
        <img width="100%" src="../assets/img/loginBackground.png" alt="" />
      </div>
      <div class="login_con">
        <div class="login_con_tel">
          <div class="login_con_tel_top">
            <div class="login_con_tel_top_img">
              <img width="100%" src="../assets/img/loginTel.png" alt="" />
            </div>
            <div class="login_con_tel_top_txt">手机号</div>
          </div>
          <div class="login_con_tel_bottom">
            <input
              type="text"
              placeholder="请输入手机号码"
              v-model="phoneNum"
              @blur="telBlur"
              disabled
            />
          </div>
        </div>
        <div class="login_con_tel login_con_code">
          <div class="login_con_tel_top">
            <div class="login_con_tel_top_img">
              <img width="100%" src="../assets/img/loginSafe.png" alt="" />
            </div>
            <div class="login_con_tel_top_txt">验证码</div>
          </div>
          <div class="login_con_tel_bottom login_con_code_bottom">
            <input type="text" placeholder="请输入验证码" v-model="code" />
            <button
              class="login_con_tel_bottom_code"
              @click="obtainCode"
              :disabled="disabled"
            >
              {{ btnmsg }}
            </button>
          </div>
        </div>
      </div>
      <div class="login_button" @click="handleLogin">
        登&nbsp;&nbsp;&nbsp;录
      </div>
    </div>
    <div class="loading" v-if="loadingShow">
      <div class="loadingBox">
        <img
          width="100%"
          height="100%"
          src="../assets/img/loading.gif"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "login",
  data() {
    return {
      btnmsg: "获取验证码", // 获取验证码按钮内容
      disabled: false, // 按钮是否禁用
      seconds: 60, // 倒计时
      phoneNum: "", // 电话号码
      code: "", // 验证码
      onlyId: "", // 唯一码
      loadingShow: false,
    };
  },
  methods: {
    // 电话号码输入框失焦 触发事件
    telBlur() {
      var tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!tel.test(this.phoneNum)) {
        Toast("手机号码有误，请重新输入");
        this.phoneNum = "";
      }
    },
    // 点击 获取验证码 触发事件
    async obtainCode() {
      if (this.phoneNum) {
        // console.log("获取二维码");
        this.disabled = true; // 获取验证码按钮不可用
        this.btnmsg = "重新发送" + this.seconds + "s";
        // 开启定时任务
        let timer = setInterval(() => {
          if (this.seconds > 0) {
            this.seconds--;
            this.btnmsg = "重新发送" + this.seconds + "s";
          } else {
            clearInterval(timer);
            this.disabled = false;
            this.seconds = 60;
            this.btnmsg = "再次发送验证码";
          }
        }, 1000);
        let params = { phoneNum: this.phoneNum };
        await this.$axios
          .post("/api/template/contract/sendCode", params)
          .then((res) => {
            if (res.data.errorCode) {
              // console.log(res);
              Toast("发送成功");
            }
          });
      } else {gi
        Toast("请输入手机号码");
      }
    },
    // 点击 登录 触发事件
    async handleLogin() {
      // this.$router.push({
      //   path: "/sign",
      // });
      if (!this.phoneNum) {
        Toast("请输入手机号码");
      } else if (!this.code) {
        Toast("请输入验证码");
      } else {
        let params = {
          phoneNum: this.phoneNum,
          code: parseInt(this.code),
          onlyId: this.onlyId,
        };
        this.loadingShow = true;
        await this.$axios
          .post("/api/template/contract/compareCode", params)
          .then((res) => {
            if (res.data.data) {
              localStorage.setItem("contractUrl", res.data.data);
              this.$router.push({
                path: "/sign",
              });
            } else {
              Toast(res.data.msg);
            }
          });
        this.loadingShow = false;
      }
    },
    // 获取唯一码 onlyId
    getOnlyId() {
      this.onlyId = localStorage.getItem("onlyId");
      this.phoneNum = localStorage.getItem("phoneNum");
    },
  },
  mounted() {
    // 获取唯一码和手机号码
    this.getOnlyId();
  },
};
</script>

<style lang="less">
.login {
  width: 100%;
  height: 100vh;
  .login_title {
    background-size: contain;
    background-repeat: no-repeat;
  }
  .login_con {
    width: 87%;
    margin: auto;
    margin-top: 0.8rem;
    .login_con_tel {
      .login_con_tel_top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.32rem;
        .login_con_tel_top_img {
          width: 1.0667rem;
          height: 1.0667rem;
          margin-right: 0.2333rem;
        }
        .login_con_tel_top_txt {
          font-size: 0.9067rem;
          font-weight: bold;
          color: #666;
        }
      }
      .login_con_tel_bottom {
        margin-top: 0.32rem;
        input {
          background: none;
          outline: none;
          border: none;
          width: 93%;
          height: 2.6667rem;
          border-radius: 0.64rem;
          background-color: #f1f1f1;
          padding-left: 7%;
          color: #404750;
          font-size: 0.8533rems;
        }
      }
      .login_con_code_bottom {
        position: relative;
        .login_con_tel_bottom_code {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          position: absolute;
          top: 50%;
          right: 0.8rem;
          transform: translateY(-50%);
          color: #3f96fd;
          font-weight: bold;
          font-size: 0.8533rem;
        }
      }
    }
    .login_con_code {
      margin-top: 1.0667rem;
    }
  }
  .login_button {
    width: 87%;
    margin: auto;
    margin-top: 4.8rem;
    height: 2.6667rem;
    line-height: 2.6667rem;
    text-align: center;
    color: #ffffff;
    font-size: 0.8533rem;
    font-weight: bold;
    background-color: #2285fc;
    border-radius: 0.64rem;
  }
}
.loading {
  width: 100%;
  height: 100vh;
  background: #666666;
  opacity: 0.5;
  position: fixed;
  top: 0%;
  z-index: 999;
  .loadingBox {
    width: 3rem;
    height: 3rem;
    margin: 50% auto;
  }
}
</style>