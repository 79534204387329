import Vue from 'vue'
import VueRouter from 'vue-router'
import Tosign from '../views/Tosign'
import Login from '../views/Login'
import Sign from '../views/Sign'
import Test from '../views/Test'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'tosign',
    component: Tosign
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/sign',
    name: 'sign',
    component: Sign
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  }
]

const router = new VueRouter({
  routes
})

export default router
