<template>
  <div id="app">
    <!-- <div class="sign_popup">
      <div class="sign_popup_title">
        <div class="sign_popup_txt">请本人在下方空白处签字</div>
      </div>
      <div class="sign_popup_con">
        <vue-esign ref="esign" direction="right" :width="325" :height="200" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"
          :bgColor.sync="bgColor" class="sign_popup_con_board" />
      </div>
      <div class="sign_popup_item">
        <div class="sign_popup_item1" @click="handleReset">清除重写</div>
        <div class="sign_popup_item2" @click="signSubmit">提交签名</div>
      </div>
    </div> -->
    <div style="border: 1px solid red;">
      <vue-esign ref="esign" :isCrop="isCrop" :lineWidth="lineWidth"
        :lineColor="lineColor" :bgColor.sync="bgColor" class="sign_popup_con_board" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lineWidth: 5, // 画笔粗细
      lineColor: "#000000", // 画笔颜色
      bgColor: "", // 画布背景
      isCrop: false, // 是否裁剪，在画布设定尺寸基础上裁掉四周空白部分
    };
  },
  methods: {
    rotate(){
      // 旋转屏幕
      window.screen.orientation.lock("landscape");
    },
    // 点击 清除重写 触发事件
    handleReset() {
      this.$refs["esign"].reset(); //清空画布
    },
    // 点击 提交签名 触发事件
    async signSubmit() {
      this.headerTipShow = true;
      await this.$refs["esign"]
        .generate()
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          //  没有签名，点击提交签名时调用
          Toast("未检测到您的签名");
        });
    },
  }
}
</script>

<style lang="less">
.sign_popup {
  width: 100%;
  height: 22.3467rem;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  bottom: 0%;
  border-radius: 0.64rem 0.64rem 0 0;

  .sign_popup_title {
    height: 3.04rem;
    position: relative;

    .sign_popup_txt {
      width: 10.6667rem;
      line-height: 3.04rem;
      margin: auto;
      color: #333333;
      font-size: 0.9067rem;
      font-weight: bold;
    }

    .sign_popup_cancel {
      width: 0.64rem;
      height: 0.64rem;
      position: absolute;
      top: 0.4rem;
      right: 0.8rem;
    }
  }

  .sign_popup_con {
    width: 92%;
    margin: auto;
    height: 12.9333rem;
    background-color: #fafafa;
    overflow: hidden;

    .sign_popup_con_board {
      margin: auto;
      border: 0.0267rem solid #ccc;
    }
  }

  .sign_popup_item {
    width: 92%;
    margin: auto;
    margin-top: 1.36rem;
    height: 2.6667rem;
    line-height: 2.6667rem;
    display: flex;
    justify-content: space-between;

    .sign_popup_item1 {
      width: 46%;
      text-align: center;
      border: 0.0267rem solid #2285fc;
      color: #2285fc;
      border-radius: 0.64rem;
    }

    .sign_popup_item2 {
      width: 46%;
      text-align: center;
      border-radius: 0.64rem;
      background-color: #2285fc;
      color: #ffffff;
    }
  }
}
</style>
